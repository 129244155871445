import * as api from '../api'
import cuid from 'cuid'

export const FETCH_START = 'FETCH_START'
export const FETCH_COMPLETE = 'FETCH_COMPLETE'
export const FETCH_FAILED = 'FETCH_FAILED'

export const OBJECTS_FETCH = 'OBJECTS_FETCH'
export const OBJECT_CREATE = 'OBJECT_CREATE'
export const OBJECT_SAVE = 'OBJECT_SAVE'
export const OBJECT_UPDATE = 'OBJECT_UPDATE'

export function encodeObject(object) {
  const flagsNames = [
    'isActive', 'isActual', 'isDefault', 'botVisible',
    'showcaseVisible', 'mailingVisible',
    'moscowRegistration'
  ]
  const flagsMasks = {
    isActive: 1,
    isActual: 2,
    isDefault: 4,
    botVisible: 8,
    showcaseVisible: 16,
    mailingVisible: 32,
    moscowRegistration: 64
  }

  const encodedFlags = flagsNames.reduce((flags, flag) => (
    object.flags[flag]
      ? flags + flagsMasks[flag]
      : flags
  ), 0)

  return {
    id: object.id,
    tel: object.tel,
    link: object.link,
    title: object.title,
    flags: encodedFlags,
    image: object.image,
    district: object.district,
    description: object.description,
    mailingDescription: object.mailingDescription,
    apiId: object.apiId,
    offer: object.offer,
    params: object.params
  }
}

export function createObject() {
  const id = cuid()
  return {type: OBJECT_CREATE, id}
}

function fetchObjectsAction(status = FETCH_START, objects = []) {
  return status === 1
    ? {type: OBJECTS_FETCH, payload: {status}}
    : {type: OBJECTS_FETCH, payload: {status, data: objects}}
}

function saveObjectAction(status = FETCH_START, idOrData) {
  return status === FETCH_START
    ? {type: OBJECT_SAVE, payload: {status, id: idOrData}}
    : {type: OBJECT_SAVE, payload: {status, data: idOrData}}
}

function updateObjectAction(status = FETCH_START, idOrData) {
  return status === FETCH_START
    ? {type: OBJECT_UPDATE, payload: {status, id: idOrData}}
    : {type: OBJECT_UPDATE, payload: {status, data: idOrData}}
}

export function fetchObjects() {
  return (dispatch) => {
    dispatch(fetchObjectsAction(FETCH_START))
    api.fetchObjects()
      .then((objects) => dispatch(fetchObjectsAction(FETCH_COMPLETE, objects)))
  }
}

export function saveObject(object) {
  return (dispatch) => {
    dispatch(saveObjectAction(FETCH_START, object.id))
    api.saveObject(encodeObject(object))
      .then((objects) => dispatch(saveObjectAction(FETCH_COMPLETE, object)))
  }
}

export function updateObject(object) {
  return (dispatch) => {
    dispatch(updateObjectAction(FETCH_START, object.id))
    api.updateObject(encodeObject(object))
      .then((objects) => dispatch(updateObjectAction(FETCH_COMPLETE, object)))
  }
}
