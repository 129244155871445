import React, {Component} from 'react'
import style from './styles/index.module.scss'

import EditContainer from '../../containers/EditContainer'
import LoginContainer from '../../containers/LoginContainer'

class Popups extends Component {
  isOverlay() {
    const {edit, login} = this.props.popupsInfo
    return edit || login ? style.opened : style.normal
  }

  render() {
    const {edit} = this.props.popupsInfo
    const {isLoggedIn} = this.props

    return (
      <div className={this.isOverlay()}>
        <EditContainer isOpen={edit} />
        {!isLoggedIn && <LoginContainer />}
      </div>
    )
  }
}

export default Popups
