import React from 'react'
import style from './styles/Tab.module.scss'

const Tab = ({isOpen, children}) => (
  <div className={isOpen ? style.active : style.normal}>
    {children}
  </div>
)

export default Tab
