import React, {Component} from 'react'
import style from './index.module.scss'

import Container from '../Container'

class Confidentiality extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  render() {
    return (
      <div className={style.normal}>
        <Container>
          <div className={style.inner}>
            <h2>Политика конфиденциальности сайта все-новостройки-москва.рф</h2>
            <p>Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации,
            которую Администрация сайта может получить о пользователе во время использования сайта все-новостройки-москва.рф.</p>
            <ul>
              <li>
                <h3>ОСНОВНЫЕ ПОНЯТИЯ</h3>
                <p>В настоящей Политике конфиденциальности используются следующие термины и понятия:</p>
                <ol>
                  <li>«АДМИНИСТРАЦИЯ ВСЕ-НОВОСТРОЙКИ-МОСКВА.РФ» (далее – Администрация сайта) – уполномоченные сотрудники на управления сайтом все-новостройки-москва.рф,
                  которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных,
                  подлежащих обработке, действия (операции), совершаемые с персональными данными.</li>
                  <li>«ПЕРСОНАЛЬНЫЕ ДАННЫЕ» – любая информация, относящаяся к определенному или определяемому на основании такой информации физическому/юридическому лицу,
                  необходимая Администрации сайта в связи с исполнением им договорных обязательств перед Пользователем.<br />
                  Кроме того, к персональным данным относятся данные, которые автоматически передаются Администрации сайта в процессе их использования с помощью установленного на
                  устройстве Пользователя программного обеспечения, в том числе IP-адрес, информация из cookie, информация о браузере пользователя (или иной программе, с помощью которой
                  осуществляется доступ к сайту), время доступа, адрес запрашиваемой страницы, местоположение и пр.
                  </li>
                  <li>«ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ» – сбор, систематизация, накопление, хранение, уточнение (обновление, изменение), использование,
                  распространение (в том числе передача), обезличивание, блокирование, уничтожение персональных данных Пользователя.</li>
                  <li>«ПОЛЬЗОВАТЕЛЬ» – лицо, которое использует сайт в порядке, регламентированном Администрацией сайта.</li>
                </ol>
              </li>
              <li>
                <h3>ОБЩИЕ ПОЛОЖЕНИЯ</h3>
                <ul>
                  <li>Использование Пользователем сайта все-новостройки-москва.рф означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.</li>
                  <li>В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта все-новостройки-москва.рф.</li>
                  <li>Администрация сайта все-новостройки-москва.рф проверяет достоверность персональных данных, предоставляемых Пользователем сайта по собственному усмотрению.</li>
                  <li>
                    Пользователь при пользовании сайтом все-новостройки-москва.рф, подтверждает, что:
                    <ol>
                      <li>обладает всеми необходимыми правами, позволяющими ему использовать услуги сайта все-новостройки-москва.рф;</li>
                      <li>указывает достоверную информацию о себе в объемах, необходимых для пользования услугами сайта все-новостройки-москва.рф;</li>
                      <li>осознает, что информация на сайте, размещаемая Пользователем о себе, может становиться доступной для третьих лиц, не оговоренных в настоящей Политике;</li>
                      <li>ознакомлен с настоящей Политикой, выражает свое согласие с ней и принимает на себя указанные в ней права и обязанности.
                      Ознакомление с условиями настоящей Политики, проставление галочки под ссылкой на данную Политику или использование сайта является письменным
                      согласием Пользователя на сбор, хранение, обработку и передачу третьим лицам персональных данных предоставляемых Пользователем.</li>
                      <li>ознакомлен с настоящей Политикой, выражает свое согласие с ней и принимает на себя указанные в ней права и обязанности.
                      Ознакомление с условиями настоящей Политики, проставление галочки под ссылкой на данную Политику или использование сайта
                      все-новостройки-москва.рф, или отправки данных посредством форм обратной связи, является письменным согласием Пользователя на сбор,
                      хранение, обработку и передачу третьим лицам персональных данных предоставляемых Пользователем.</li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>
                <h3>ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</h3>
                <ul>
                  <li>Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта по неразглашению и
                  обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта
                  при заполнении регистрационной формы, или формы обратной связи.</li>
                  <li>Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем добровольно.</li>
                  <li>Администрация осуществляет сбор статистики об IP-адресах своих пользователей. Данная информация используется с целью выявления
                  и решения технических проблем, для контроля законности действий Пользователя.</li>
                </ul>
              </li>
              <li>
                <h3>ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</h3>
                <ul>
                  <li>
                    Персональные данные Пользователя Администрация сайта все-новостройки-москва.рф может использовать в целях:
                    <ol>
                      <li>Установления с Пользователем обратной связи, включая направление уведомлений, запросов, рассылок, касающихся использования сайта.</li>
                      <li>Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</li>
                      <li>Выполнение договорных и преддоговорных обязательств в отношении Пользователя.</li>
                      <li>Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.</li>
                      <li>Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием сайта.</li>
                      <li>Предоставления Пользователю с его согласия, обновлений сайта, специальных предложений, новостной рассылки и иных сведений от имени сайта.</li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>
                <h3>СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</h3>
                <ul>
                  <li>Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в
                  информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</li>
                  <li>При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении персональных данных.</li>
                  <li>Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от
                  неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</li>
                  <li>Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий,
                  вызванных утратой или разглашением персональных данных Пользователя.</li>
                </ul>
              </li>
              <li>
                <h3>ОБЯЗАТЕЛЬСТВА СТОРОН</h3>
                <ul>
                  <li>
                    Пользователь обязан:
                    <ul>
                      <li>Предоставить актуальную информацию о персональных данных, необходимую для пользования сайтом все-новостройки-москва.рф.</li>
                      <li>Обновить, дополнить предоставленную Администрацией сайта информацию о персональных данных в случае изменения данной информации.</li>
                    </ul>
                  </li>
                  <li>
                    Администрация сайта обязана:
                    <ul>
                      <li>Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.</li>
                      <li>Обеспечить хранение конфиденциальной информации в тайне.</li>
                      <li>Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя.</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <h3>ОТВЕТСТВЕННОСТЬ СТОРОН</h3>
                <ul>
                  <li>Администрация сайта несёт ответственность в соответствии с законодательством Российской Федерации.</li>
                  <li>
                    В случае утраты или разглашения конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация:
                    <ul>
                      <li>Стала публичным достоянием до её утраты или разглашения.</li>
                      <li>Была получена от третьей стороны до момента её получения Администрацией сайта.</li>
                      <li>Была разглашена с согласия Пользователя или самим Пользователем.</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <h3>РАЗРЕШЕНИЕ СПОРОВ</h3>
                <ul>
                  <li>До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта и Администрацией сайта, обязательным является предъявление
                  претензии (письменного предложения о добровольном урегулировании спора).</li>
                  <li>Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</li>
                  <li>К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство Российской Федерации.</li>
                </ul>
              </li>
              <li>
                <h3>ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h3>
                <ul>
                  <li>Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.</li>
                  <li>Новая Политика конфиденциальности вступает в силу с момента ее размещения на сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.</li>
                </ul>
              </li>
            </ul>
            <h3><span>ПРИЛОЖЕНИЕ №1</span>
            <span>СОГЛАСИЕ ПОЛЬЗОВАТЕЛЯ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</span></h3>
            <p>Пользователь, оставляя свои данные на сайте все-новостройки-москва.рф, принимает настоящее Соглашение на обработку персональных данных (далее – Согласие).
            Принятием (акцептом) оферты Согласия является предоставление пользователем своих персональных данных Администрации сайта все-новостройки-москва.рф.</p>
            <p>Пользователь дает свое согласие Администрации сайта, которой принадлежит сайт все-новостройки-москва.рф и, который расположен по адресу: все-новостройки-москва.рф на обработку
            своих персональных данных со следующими условиями:</p>
            <ol>
              <li>Данное Согласие дается на обработку персональных данных, как без использования средств автоматизации, так и с их использованием.</li>
              <li>Согласие дается на обработку следующих персональных данных Пользователя:</li>
              <li>Персональные данные, не являющиеся специальными или биометрическими.</li>
              <li>Цели обработки персональных данных указаны в п.4 Политики защиты персональной информации Пользователей сайта все-новостройки-москва.рф.</li>
              <li>Основанием для обработки персональных данных являются: Ст. 24 Конституции Российской Федерации; ст.6 Федерального закона №152-ФЗ «О персональных данных».</li>
              <li>В ходе обработки с персональными данными будут совершены следующие действия: сбор; запись; систематизация; накопление; хранение; уточнение (обновление, изменение);
              извлечение; использование; передачу (распространение, предоставление, доступ); обезличивание; блокирование; удаление; уничтожение.</li>
              <li>Передача персональных данных третьим лицам осуществляется на основании законодательства Российской Федерации, договора с участием
              субъекта персональных данных или с согласия субъекта персональных данных. Пользователь дает свое согласие на возможную передачу своих персональных данных третьим лицам.</li>
              <li>Персональные данные обрабатываются до окончания обработки. Так же обработка персональных данных может быть прекращена по запросу субъекта
              персональных данных. Срок или условие прекращения обработки персональных данных: прекращение деятельности Администрации сайта.</li>
              <li>Согласие дается, в том числе на возможную трансграничную передачу персональных данных и информационные (рекламные) оповещения.</li>
              <li>Согласие может быть отозвано субъектом персональных данных или его представителем, путем направления письменного заявления Администрации сайта или
              его представителю по адресу, указанному в начале данного Согласия.</li>
              <li>В случае отзыва субъектом персональных данных или его представителем согласия на обработку персональных данных Администрация сайта вправе продолжить
              обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и
              части 2 статьи 11 Федерального закона №152-ФЗ «О персональных данных» от 26.06.2006 г.</li>
            </ol>
          </div>
        </Container>
      </div>
    )
    }
}

export default Confidentiality
