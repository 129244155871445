import React, {Component} from 'react'
import style from './index.module.scss'

class CheckBox extends Component {
  handleChange = (event) => {
    const {name, isChecked, onChange} = this.props
    if (typeof onChange === 'function') {
      onChange(name, !isChecked)
    }
  }

  render() {
    return (
      <input
        className={style.input}
        type="checkbox"
        name="moscowRegistration"
        checked={this.props.isChecked}
        onChange={this.handleChange}
      />
    )
  }
}

export default CheckBox
