import { SESSION_UPDATE } from '../actions/session'

const initialState = {
  id: null,
  datetime: null,
  queryParams: {},
  clientId: null
}

function sessionReducer(state = initialState, action) {
  if (action.type === SESSION_UPDATE) {
    return {...state, ...action.session}
  }
  return state
}

export default sessionReducer
