import React, {Component} from 'react'
import style from './styles/Checkbox.module.scss'

class Checkbox extends Component {
  handleChange = ({target}) => {
    const {name, value, onChange} = this.props

    if (typeof onChange === 'function') {
      onChange(name, !value)
    }
  }

  render() {
    const {label, value, className: mixin} = this.props
    return (
      <div className={`${style.normal} ${mixin ? mixin : ''}`}>
        <label className={value ? style.activeLabel : style.label}>
          <input className={style.input} type="checkbox" onChange={this.handleChange}/>
          {label && label}
        </label>
      </div>
    )
  }
}

export default Checkbox
