import React, {Component} from 'react'
import style from './styles/Group.module.scss'

import ImageUploader from './ImageUploader'
import Checkbox from './Checkbox'
import Select from './Select'
import Input from './Input'
import Radio from './Radio'

class Group extends Component {
  getValue = (name) => {
    const {card} = this.props
    return typeof name === 'string'
      ? card[name]
      : card[name[0]][name[1]]
  }

  getForms(form) {
    const {type, name, label} = form
    const {card, onChange} = this.props
    const value = card ? this.getValue(name) : null

    switch(type) {
      case 'input':
      case 'textarea':
        return <Input key={name} name={name} type={type} label={label} value={value} onChange={onChange} />
      case 'checkbox':
        return <Checkbox key={name} name={name} label={label} value={value} onChange={onChange} />
      case 'image':
        return <ImageUploader key={name} name={name} img={value} />
      case 'radio':
        return <Radio key={name} name={name} title={label} value={value} inputs={form.inputs} onChange={onChange} />
      case 'select':
        return <Select key={name} name={name} label={label} values={form.values} selected={value} onChange={onChange} />
      default:
        return type
    }
  }

  prepareForms(form) {
    const {group} = this.props
    return group.some(f => JSON.stringify(form.name) === JSON.stringify(f))
  }

  render() {
    const {forms, title} = this.props
    return (
      <div className={style.normal}>
          {title && <h3 className={style.title}>{title}</h3>}
          {forms.filter(f => this.prepareForms(f)).map(f => this.getForms(f))}
      </div>
    )
  }
}

export default Group
