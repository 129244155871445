function encodeParams(params) {
  return Object.getOwnPropertyNames(params)
    .filter((param) => params[param])
    .map((param) => `${param}=${params[param]}`)
    .join('&')
}

export function makeTail({session}) {
  return encodeParams({
    client_id: session.clientId || null,
    user_id: session.userId || null,
    ...session.queryParams
  })
}
