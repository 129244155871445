import React, {Component} from 'react'
import style from './styles/Tabs.module.scss'

class Tabs extends Component {
  handleClick = (event) => {
    event.preventDefault()

    const {name, onTab} = this.props
    const value = event.target.getAttribute('data-value')

    if (typeof onTab === 'function') {
      onTab(name, value)
    }
  }

  handleChange = ({target}) => {
    const {values, onChange} = this.props
    const {name} = target
    const secureName = typeof name === "string" ? name.split(',') : name
    const secureValue = typeof secureName === 'string'
      ? values[secureName]
      : values[secureName[0]][secureName[1]]

    if (typeof onChange === 'function') {
      onChange(secureName, !secureValue)
    }
  }

  isActive(name) {
    const {values} = this.props

    if (!values) {
      return false
    }

    return typeof name === 'string'
      ? values[name]
      : values[name[0]][name[1]]
  }

  render() {
    const {current, tabs, children} = this.props
    return (
      <div className={style.normal}>
        <ul className={style.list}>
          {tabs.map(t => (
            <li key={t.value} className={current === t.value ? style.activeLi : style.li}>
              {t.form && <label className={this.isActive(t.form) ? style.activeLabel : style.label}>
                <input
                  className={style.input}
                  type="checkbox"
                  name={t.form}
                  onChange={this.handleChange}
                />
              </label>}
              <button
                className={style.button}
                data-value={t.value}
                onClick={this.handleClick}
                >
                  {t.label}
                </button>
              </li>
            ))}
          </ul>
          {children}
      </div>
    )
  }
}

export default Tabs
