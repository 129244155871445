import React, {Component} from 'react'
import style from './styles/index.module.scss'

import Container from '../Container'
import Card from './Card'

class CardsList extends Component {
  componentDidMount() {
    const {onMount} = this.props
    if (typeof onMount === 'function') {
      onMount()
    }
  }

  render() {
    const {tail, objects, isFetching, isLoggedIn, onClick, onEdit, onCreate} = this.props
    return (
      <div className={style.normal}>
        <Container>
          {isFetching && <div className={style.loading}></div>}
          <div className={style.list}>
            {objects.map(object => (
              <Card
                className={style.cardHover}
                key={object.id}
                tail={tail}
                object={object}
                isLoggedIn={isLoggedIn}
                onEdit={onEdit}
                onClick={onClick}
              />
            ))}
            {isLoggedIn && <button className={style.addCard} onClick={onCreate}></button>}
          </div>
        </Container>
      </div>
    )
  }
}

export default CardsList
