import React, {Component} from 'react'
import style from './styles/Card.module.scss'

import Icon from '../utility/Icon'

function makeLink(base, tail) {
  if (tail.length > 0) {
    return base.match(/\?/)
      ? base + `&${tail}`
      : base + `?${tail}`
  }
  return base
}

class Card extends Component {
  handleClick = (event) => {
    event.preventDefault()

    const {onEdit, object} = this.props

    if (typeof onEdit === 'function') {
      onEdit(object.id)
    }
  }

  getCardClassName() {
    const {className: mixin, object} = this.props
    const {isActive, showcaseVisible} = object.flags
    const opacity = isActive && showcaseVisible ? '' : style.opacity
    return `${style.normal} ${mixin ? mixin : ''} ${opacity}`
  }

  handleTarget = () => {
    const {object, onClick} = this.props
    const {ym} = window
    if (typeof ym === 'function') {
      ym(52500127, 'reachGoal', 'TO_PARTNER')
    }
    if (typeof onClick === 'function') {
      onClick(object)
    }
  }

  render() {
    const {tail, object, isLoggedIn} = this.props
    const {title, link: linkBase, image, offer, params} = object
    const {startPrice: price, delivery: term} = offer || {}
    const link = linkBase !== null ? makeLink(linkBase, tail) : ''

    const secureParams = {
      metroName: params.metroName || '',
      metroDistance: params.metroDistance || '',
      metroMethod: params.metroMethod || 'foot'
    }

    return (
      <div className={this.getCardClassName()}>
        {isLoggedIn && <button className={style.edit} onClick={this.handleClick}><Icon icon="edit" /></button>}
        <a href={link} className={style.link} target="_blank" rel="noopener noreferrer" onClick={this.handleTarget}>
        <div className={style.image}>
          <figure className={style.figure}>
            <img src={image} className={style.img} alt="" />
            <figcaption className={style.figcaption}>
              <ul>
                <li><span className={style.circle}><Icon className={style.metro} icon="metro" /></span>{secureParams.metroName}</li>
                <li className={style.method}><Icon icon={secureParams.metroMethod} />{secureParams.metroDistance} <small>мин</small></li>
              </ul>
            </figcaption>
          </figure>
        </div>
        <p className={style.price}>от <span>{price}</span> млн</p>
        <h3 className={style.title}>{title}</h3>
        <p className={style.term}><span><Icon icon="plus" className={style.icon} /></span>{term}</p>
      </a>
    </div>
  )
}
}

export default Card
