import {GEOLOCATION_FETCH} from '../actions/geolocation'

const initialState = {
  info: null
}

function geolocationReducer(state = initialState, action) {
  if (action.type === GEOLOCATION_FETCH) {
    return {...state, info: action.info}
  }

  return state
}

export default geolocationReducer
