import {POPUP_OPEN, POPUP_CLOSE, OBJECT_SELECT} from '../actions/popups'
import {OBJECT_CREATE} from '../actions/object'

const initialState = {
  login: false,
  edit: false,
  card: null
}

function popupReducer(state = initialState, action) {
  switch (action.type) {
    case POPUP_OPEN:
      return {
        ...state,
        [action.name]: true
      }
    case POPUP_CLOSE:
      return initialState
    case OBJECT_SELECT:
    case OBJECT_CREATE:
      return {
        ...state,
        edit: true,
        card: action.id
      }
    default:
      return state
  }
}

export default popupReducer
