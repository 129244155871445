import {prepareObject} from './objects'

export function getEditingCard({popup, objects}) {
  const id = popup.card
  if (id) {
    return prepareObject(objects.byId[id])
  }

  return {}
}

export function isLoginOpen({popup}) {
  return popup.login
}
