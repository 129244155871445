import {combineReducers} from 'redux'
import objects from './objects'
import filters from './filters'
import popup from './popup'
import auth from './auth'
import geolocation from './geolocation'
import session from './session'

export default combineReducers({
  objects,
  popup,
  auth,
  filters,
  geolocation,
  session
})
