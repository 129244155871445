const baseUrl = 'https://api.все-новостройки-москва.рф'

export function fetchObjects() {
  return fetch(`${baseUrl}/v1/objects/all`)
    .then(r => r.json())
}

export function saveObject(object) {
  return fetch(`${baseUrl}/v1/objects`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(object)
  }).then(r => r.json())
}

export function updateObject(object) {
  return fetch(`${baseUrl}/v1/objects`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(object)
  }).then(r => r.json())
}

export function login(login, password) {
  return fetch(`${baseUrl}/v1/auth`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({login, password})
  }).then(r => r.json())
}

export function sendEvent(event) {
  return fetch(`${baseUrl}/v1/events`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(event)
  }).then(r => r.json())
}
