import React, {Component} from 'react'
import style from './styles/Login.module.scss'

import Icon from '../utility/Icon'

class Login extends Component {
  state = {
    login: '',
    pass: ''
  }

  componentDidMount() {
    const {onOpen} = this.props

    if (typeof onOpen === 'function') {
      onOpen()
    }
  }

  handleClose = (event) => {
    event.preventDefault()

    const {onClose} = this.props
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  handleChange = ({target}) => {
    const {name, value} = target

    this.setState({[name]: value})
  }

  handleLogin = (event) => {
    event.preventDefault()

    const {onLogin} = this.props
    const {login, pass} =this.state

    if (typeof onLogin === 'function') {
      onLogin(login, pass)
    }
  }

  render() {
    const {isError, isSending, isOpen} = this.props
    const {login, pass} = this.state

    return (
      <div className={isSending ? style.spinner : isOpen ? style.open : style.normal}>
        <button className={style.close} onClick={this.handleClose}><Icon icon="close" /></button>
        <h3 className={style.title}>Авторизация</h3>
        <label className={style.inputWrap}>
          <span className={style.label}>Логин:</span>
          <input
            className={style.input}
            type="text"
            name="login"
            value={login}
            onChange={this.handleChange}
          />
        </label>
        <label className={style.inputWrap}>
          <span className={style.label}>Пароль:</span>
          <input
            className={style.input}
            type="password"
            name="pass"
            value={pass}
            onChange={this.handleChange}
          />
        </label>
        <p className={isError ? style.activeError : style.error}>Не правильно введен логин или пароль.</p>
        <button className={style.login} onClick={this.handleLogin}>Войти</button>
      </div>
    )
  }
}

export default Login
