import React, {Component} from 'react'
import style from './styles/Input.module.scss'

class Input extends Component {
  handleChange = ({target}) => {
    const {name, onChange} = this.props
    const {value} = target

    if (typeof onChange === 'function') {
      onChange(name, value)
    }
  }

  render() {
    const {type, label, value} = this.props
    return (
      <div className={style.normal}>
        <h4 className={style.label}>{label}</h4>
        {type === 'textarea'
          ? <textarea
              className={style.textarea}
              value={value || ''}
              onChange={this.handleChange}
            ></textarea>
          : <input
              type="text"
              className={style.input}
              value={value || ''}
              onChange={this.handleChange}
            />
        }
      </div>
    )
  }
}

export default Input
