import {FILTER_CHANGE} from '../actions/filters'

const initialState = {
  moscowRegistration: false
}

export function filterReducer(state = initialState, action) {
  if (action.type === FILTER_CHANGE) {
    return {
      ...state,
      [action.name]: action.value
    }
  }

  return state
}

export default filterReducer
