import React, {Component} from 'react'

import style from './styles/Select.module.scss'

class Select extends Component {
  handleChange = ({target}) => {
    const {name, onChange} = this.props
    const {value} = target

    if (typeof onChange === 'function') {
      onChange(name, value)
    }
  }

  render() {
    const {label, selected, values = []} = this.props
    const secureSelected = selected ? selected : ''

    return (
      <div className={style.normal}>
        <h4 className={style.label}>{label}</h4>
        <select className={style.select} value={secureSelected} onChange={this.handleChange}>
          {values.map(v => (
            <option className={style.options} key={v.value} value={v.value}>{v.title}</option>
          ))}
        </select>
      </div>
    )
  }
}

export default Select
