import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom"
import {Provider} from 'react-redux'
import style from './styles/App.module.scss'

import Confidentiality from './components/Inner/Confidentiality'
import Footer from './components/Footer'
import HeaderContainer from './containers/HeaderContainer'
import CardsContainer from './containers/CardsContainer'
import PopupsContainer from './containers/PopupsContainer'

import store from './store'

class App extends Component {
  render() {
    return (
      <Router>
        <Provider store={store}>
          <div className={style.App}>
            <Route path="/" exact component={HeaderContainer} />
            <Route path="/:id" component={HeaderContainer} />
            <Route path="/" exact component={CardsContainer} />
            <Route path="/manage/" component={CardsContainer} />
            <Route path="/confidentiality/" component={Confidentiality} />
            <Footer />
            <Route path="/manage/" component={PopupsContainer} />
          </div>
        </Provider>
      </Router>
    )
  }
}

export default App
