import queryString from 'querystring'
import cuid from 'cuid'
import * as api from '../api'

export const SESSION_UPDATE = 'SESSION_UPDATE'

export const updateSession = (session) => ({type: SESSION_UPDATE, session})

const camelCase = s => s.replace(/_([a-z])/g, g => g[1].toUpperCase())
const queryParam = (p, {queryParams: params}) => params[p] ? params[p] : null
const ipInfo = (item, {info}) => info ? info[item] || null : null

export function getDateTime() {
  const date = new Date()
  return date.toISOString().slice(0, 19).replace('T', ' ')
}

export function getDateTimeExtra() {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()
  const f = v => v < 10 ? '0' + v : v
  return {
    local: `${year}-${f(month)}-${f(day)} ${f(hours)}:${f(minutes)}:${f(seconds)}`,
    utcDateTime: date.toISOString().slice(0, 19).replace('T', ' '),
    utcDate: date.toISOString().slice(0, 10)
  }
}

function getQueryParams() {
  const params = window.location.search.substr(1)
  return queryString.parse(params)
}

function waitForClientId() {
  let numberOfTries = 0
  return new Promise((resolve, reject) => {
    function getClientId() {
      const counter = window.yaCounter52500127 || null
      if (counter && counter.getClientID) {
        resolve(counter.getClientID())
      } else if (numberOfTries++ < 50) {
        setTimeout(getClientId, 200)
      } else {
        resolve(null)
      }
    }
    getClientId()
  })
}

function utmExtra({queryParams}) {
  return Object.getOwnPropertyNames(queryParams)
    .filter(param => param.startsWith('utm_'))
    .reduce((r, p) => (r[camelCase(p)] = queryParams[p], r), {})
}

function extraValues(session, geolocation) {
  const extra = [
    {
      name: 'userCountry',
      value: ipInfo('country', geolocation)
    },
    {
      name: 'userId',
      value: session.userId || null
    }
  ]
  return extra.reduce((r, v) => {
    if (v.value) {
      r[v.name] = v.value
    }
    return r
  }, {})
}

export function makeFullEvent(event, {session, geolocation}) {
  const datetime = getDateTimeExtra()
  return {
    eventName: event.name,
    yClickId: queryParam('yclick_id', session),
    clientId: session.clientId || null,
    utmExtraValues: utmExtra(session),
    eventExtraValues: event.data || {},
    eventDate: datetime.utcDate,
    eventDateTime: datetime.utcDateTime,
    userIP: ipInfo('ip', geolocation),
    userCity: ipInfo('city', geolocation),
    userRegion: ipInfo('region', geolocation),
    userLocalTime: datetime.local,
    extraValues: extraValues(session, geolocation),
    eventVersion: 1
  }
}

function getUserId() {
  const saved = localStorage.getItem('userId')
  if (saved) {
    return saved
  }
  const newId = cuid()
  localStorage.setItem('userId', newId)
  return newId
}

export function sendEvent(event) {
  return (dispatch, getState) => {
    api.sendEvent(makeFullEvent(event, getState()))
  }
}

export function initSession() {
  return (dispatch, getState) => {
    const session = {
      id: cuid(),
      userId: getUserId(),
      datetime: getDateTime(),
      queryParams: getQueryParams()
    }
    dispatch(updateSession(session))
    waitForClientId().then(clientId => {
      const session = {clientId}
      dispatch(updateSession(session))
    })
  }
}
