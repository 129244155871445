import * as api from '../api'
import {closePopup} from './popups'

export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export function loginAction(status, data) {
  switch (status) {
    case 1:
      return {type: AUTH_LOGIN, payload: {status}}
    case 2:
      return {type: AUTH_LOGIN, payload: {status, token: data}}
    default:
      return {type: AUTH_LOGIN, payload: {status, error: data}}
  }
}

export function login(login, password) {
  return (dispatch) => {
    dispatch(loginAction(1))
    api.login(login, password).then((response) => {
      if (response.error) {
        dispatch(loginAction(0, response.error))
      } else {
        dispatch(loginAction(2, response.token))
        dispatch(closePopup())
      }
    })
  }
}
