import React from 'react'
import {Link} from 'react-router-dom'
import style from './index.module.scss'
import CheckBox from './CheckBox'
import Container from '../Container'
import Icon from '../utility/Icon'

const Header = ({path, isActive, onChange}) => (
  <div className={style.normal}>
    <Container>
      <div className={style.header}>
        <h2 className={style.title}><Link className={style.link} to="/">Новостройки москвы&nbsp;<span className={style.tm}>TM</span></Link></h2>
        {path !== 'confidentiality' && <label className={style.label}>только Московская прописка
          <span className={style.checkbox}>{isActive && <Icon icon="check" className={style.icon} />}</span>
          <CheckBox
            name="moscowRegistration"
            isChecked={isActive}
            onChange={onChange}
          />
        </label>}
      </div>
    </Container>
  </div>
)

export default Header
