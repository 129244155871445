import {connect} from 'react-redux'

import {openPopup, closePopup} from '../actions/popups'
import * as actions from '../actions/auth'
import {isSending, isError} from '../selectors/auth'
import {isLoginOpen} from '../selectors/popups'

import Login from '../components/Popups/Login'

const mapStateToProps = (state) => ({
  isOpen: isLoginOpen(state),
  isError: isError(state),
  isSending: isSending(state)
})

const mapDispatchToProps = (dispatch) => ({
  onOpen() {
    dispatch(openPopup('login'))
  },
  onLogin(login, pass) {
    dispatch(actions.login(login, pass))
  },
  onClose() {
    dispatch(closePopup())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
