export function isLoggedIn({auth}) {
  return !!auth.token
}

export function isSending({auth}) {
  return auth.isPosting
}

export function isError({auth}) {
  return auth.hasErrors
}
