export const discrictsValues = [
  {
    value: 'central-district',
    title: 'Центральный'
  },
  {
    value: 'north-distrcit',
    title: 'Северный'
  },
  {
    value: 'north-east-district',
    title: 'Северо-Восточный'
  },
  {
    value: 'east-district',
    title: 'Восточный'
  },
  {
    value: 'south-east-distrcit',
    title: 'Юго-Восточный'
  },
  {
    value: 'south-distrcit',
    title: 'Южный'
  },
  {
    value: 'south-west-distrcit',
    title: 'Юго-Западный'
  },
  {
    value: 'west-district',
    title: 'Западный'
  },
  {
    value: 'north-west-distrcit',
    title: 'Северо-Западный'
  },
  {
    value: 'zelenogradskiy-distrcit',
    title: 'Зеленоградский'
  },
  {
    value: 'novomoskovskiy-distrcit',
    title: 'Новомосковский'
  }
]

export const forms = [
  {
    name: 'tel',
    type: 'input',
    label: 'Телефон'
  },
  {
    name: 'link',
    type: 'input',
    label: 'Ссылка'
  },
  {
    name: 'title',
    type: 'input',
    label: 'Название'
  },
  {
    name: 'image',
    type: 'input',
    label: 'Картинка'
  },
  {
    name: 'district',
    type: 'select',
    label: 'Округ',
    values: discrictsValues
  },
  {
    name: 'description',
    type: 'textarea',
    label: 'Описание'
  },
  {
    name: 'mailingDescription',
    type: 'input',
    label: 'Описания на e-mail'
  },
  {
    name: 'apiId',
    type: 'input',
    label: 'Api ID'
  },
  {
    name: ['flags', 'isActive'],
    type: 'checkbox',
    label: 'Опубликован'
  },
  {
    name: ['flags', 'isActual'],
    type: 'checkbox',
    label: 'Актуальный'
  },
  {
    name: ['flags', 'isDefault'],
    type: 'checkbox',
    label: 'Показывать по умолчанию'
  },
  {
    name: ['flags', 'botVisible'],
    type: 'checkbox',
    label: 'Для бота'
  },
  {
    name: ['flags', 'showcaseVisible'],
    type: 'checkbox',
    label: 'Для витрины'
  },
  {
    name: ['flags', 'mailingVisible'],
    type: 'checkbox',
    label: 'Для e-mail'
  },
  {
    name: ['offer', 'delivery'],
    type: 'input',
    label: 'Дата сдачи'
  },
  {
    name: ['offer', 'startPrice'],
    type: 'input',
    label: 'Начальная цена'
  },
  {
    name: ['offer', 'offerStart'],
    type: 'input',
    label: 'Актуально с'
  },
  {
    name: ['offer', 'offerEnd'],
    type: 'input',
    label: 'Актуально до'
  },
  {
    name: ['offer', 'tel'],
    type: 'input',
    label: 'Телефон оффера'
  },
  {
    name: ['offer', 'link'],
    type: 'input',
    label: 'Ссылка оффера'
  },
  {
    name: ['params', 'metroName'],
    type: 'input',
    label: 'Метро'
  },
  {
    name: ['params', 'metroDistance'],
    type: 'input',
    label: 'Время до метро'
  },
  {
    name: ['params', 'metroMethod'],
    type: 'radio',
    label: 'Пешком/на машине',
    inputs: [
      {
        value: 'foot',
        label: 'Пешком'
      },
      {
        value: 'drive',
        label: 'На машине'
      }
    ]
  },
  {
    name: ['flags', 'moscowRegistration'],
    type: 'checkbox',
    label: 'Московская прописка'
  }
]

export const formsGroups = [
  {
    title: 'Витрина',
    id: 'showcase',
    forms: [
      ['params', 'metroName'],
      ['params', 'metroDistance'],
      ['params', 'metroMethod'],
      ['flags', 'moscowRegistration']
    ]
  },
  {
    title: 'Бот',
    id: 'bot',
    forms: [
      'description',
      ['flags', 'isDefault']
    ]
  },
  {
    title: 'Мейлинг',
    id: 'mailing',
    forms: [
      'mailingDescription'
    ]
  }
]

export const mainGroups = [
  {
    title: 'Общие',
    id: 'main',
    forms: [
      'title',
      'image',
      'district'
    ]
  },
  {
    title: 'Партнерка',
    id: 'api',
    forms: [
      'tel',
      'link',
      'apiId'
    ]
  }
]

export const mainTabs = [
  {
    label: 'Общие',
    value: 'main'
  },
  {
    label: 'Партнерка',
    value: 'api',
    form: ['flags', 'isActual']
  }
]

export const formsTabs = [
  {
    label: 'Витрина',
    value: 'showcase',
    form: ['flags', 'showcaseVisible']
  },
  {
    label: 'Бот',
    value: 'bot',
    form: ['flags', 'botVisible']
  },
  {
    label: 'Мейлинг',
    value: 'mailing',
    form: ['flags', 'mailingVisible']
  }
]

export const formsOffers = [
  ['offer', 'offerStart'],
  ['offer', 'offerEnd'],
  ['offer', 'tel'],
  ['offer', 'link'],
  ['offer', 'startPrice'],
  ['offer', 'delivery']
]
