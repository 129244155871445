function compareDates(first, second) {
  const [firstYear, firstMonth, firstDay] = first.split('-').map(parseInt)
  const [secondYear, secondMonth, secondDay] = second.split('-').map(parseInt)
  if (firstYear > secondYear) {
    return 1
  }
  if (firstYear < secondYear) {
    return -1
  }
  if (firstMonth > secondMonth) {
    return 1
  }
  if (firstMonth < secondMonth) {
    return -1
  }
  if (firstDay > secondDay) {
    return 1
  }
  if (firstDay < secondDay) {
    return -1
  }
  return 0
}

function selectNearestOffer(offers) {
  return Object.getOwnPropertyNames(offers)
    .reduce((nearest, offerStart) => {
      if (nearest) {
        return compareDates(offerStart, nearest.offerStart) < 0
          ? offers[offerStart]
          : nearest
      }
      return offers[offerStart]
    }, null)
}

export function prepareObject({offers, ...object}) {
  const offer = selectNearestOffer(offers)
  return {...object, offer}
}

export function getAllObjects({objects}) {
  return objects.all.map((id) => prepareObject(objects.byId[id]))
}

export function getShowcaseObjects({objects, filters}) {
  const isActive = filters.moscowRegistration
  return objects.all
    .map((id) => prepareObject(objects.byId[id]))
    .filter(({flags}) => {
      return isActive ? flags.moscowRegistration : true
    })
    .filter(({flags}) => {
      const {isActive, isActual, showcaseVisible} = flags
      return isActive && isActual && showcaseVisible
    })
}

export function getFetchingState({objects}) {
  return objects.isFetching
}
