import {connect} from 'react-redux'

import {changeFilter} from '../actions/filters'
import {sendEvent} from '../actions/session'

import Header from '../components/Header'

function encodeValue(value) {
  if (typeof value === 'boolean') {
    return value ? 'checked' : 'unchecked'
  }
  return value
}

const mapStateToProps = ({filters}, props) => ({
  isActive: filters.moscowRegistration,
  path: props.match.params.id
})

const mapDispatchToProps = (dispatch) => ({
  onChange(name, value) {
    dispatch(sendEvent({
      name: 'change_filter',
      data: {
        filter: name,
        value: encodeValue(value)
      }
    }))
    dispatch(changeFilter(name, value))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
