import * as actions from '../actions/object'

const initialState = {
  isFetching: false,
  byId: {},
  all: []
}

const objectTemplate = (id) => ({
  isNew: true,
  isPosting: false,
  id,
  tel: '',
  link: '',
  title: 'Новый объект',
  flags: {
    isActive: false,
    isActual: false,
    isDefault: false,
    botVisible: false,
    showcaseVisible: false,
    mailingVisible: false,
    moscowRegistration: false
  },
  image: '',
  district: '',
  description: '',
  mailingDescription: '',
  apiId: '',
  offers: {
    'null': {
      delivery: '',
      startPrice: 0,
      offerStart: null,
      offerEnd: null,
      tel: null,
      link: null
    }
  },
  params: {}
})

function loadOffers(object) {
  return object.offers.reduce((result, offer) => {
    result[offer.offerStart] = offer
    return result
  }, {})
}

function decodeObject(object) {
  const {flags} = object
  return {
    ...object,
    isNew: false,
    isPosting: false,
    flags: {
      isActive: (flags & 1) === 1,
      isActual: (flags & 2) === 2,
      isDefault: (flags & 4) === 4,
      botVisible: (flags & 8) === 8,
      showcaseVisible: (flags & 16) === 16,
      mailingVisible: (flags & 32) === 32,
      moscowRegistration: (flags & 64) === 64
    },
    offers: loadOffers(object),
    params: object.params
  }
}

function loadObjects(objects) {
  return objects.reduce((result, object) => {
    result[object.id] = decodeObject(object)
    return result
  }, {})
}

function listObjects(objects) {
  return objects.map(object => object.id)
}

function fetchObjects(state, payload) {
  return payload.status === actions.FETCH_START
    ? {...state, isFetching: true}
    : {
      ...state,
      isFetching: false,
      byId: loadObjects(payload.data),
      all: listObjects(payload.data)
    }
}

function updateObjectWithId(prev, id, updates) {
  return {...prev, [id]: {...prev[id], ...updates}}
}

function notNullOffers(offers) {
  return Object.getOwnPropertyNames(offers)
    .filter(name => name !== 'null')
    .reduce((result, name) => {
      result[name] = offers[name]
      return result
    }, {})
}

function replaceObjectWithId(prev, id, {offer, ...data}) {
  const updatedOffers = {
    ...notNullOffers(prev[id].offers),
    [offer.offerStart]: offer
  }
  return {...prev, [id]: {...data, isNew: false, offers: updatedOffers}}
}

function updateObject(state, payload) {
  const {id, data, status} = payload
  const {byId} = state
  return status === actions.FETCH_START ? {
      ...state,
      byId: updateObjectWithId(byId, id, {isPosting: true})
    } : {
      ...state,
      byId: replaceObjectWithId(byId, data.id, data)
    }
}

function createObject(state, action) {
  return {
    ...state,
    byId: {
      ...state.byId, [action.id]: objectTemplate(action.id)
    },
    all: state.all.concat(action.id)
  }
}

function objectsReducer(state = initialState, action) {
  const {type, payload} = action

  switch (type) {
    case actions.OBJECTS_FETCH:
      return fetchObjects(state, payload)
    case actions.OBJECT_UPDATE:
    case actions.OBJECT_SAVE:
      return updateObject(state, payload)
    case actions.OBJECT_CREATE:
      return createObject(state, action)
    default:
      return state
  }
}

export default objectsReducer
