import React from 'react'
import {Link} from "react-router-dom"
import style from './index.module.scss'

import Container from '../Container'

const Footer = () => (
  <div className={style.normal}>
    <Container>
      <div className={style.footer}>
        <small className={style.copy}>2019 &copy; все права защищены.</small>
        <Link to="/confidentiality" className={style.link}>политика конфиденциальности</Link>
      </div>
    </Container>
  </div>
)

export default Footer
