import React, {Component} from 'react'
import style from './styles/UploadImage.module.scss'

class ImageUploader extends Component {
  state = {
    image: this.props.img
  }

  handleChange = (event) => {
    const {name, onChange} = this.props
    var reader = new FileReader()

    reader.addEventListener("load", (event) => {
      this.setState({image: event.target.result})

      if(typeof onChange === "function") {
        onChange(name, event.target.result)
      }
    })
    reader.readAsDataURL(this.fileUpload.files[0])
  }

  render() {
    const {img} = this.props

    return (
      <figure className={style.normal}>
        <img className={style.img} src={img} alt="" />
        <figcaption className={style.figcaption}>
          <label className={style.label}>
            <input className={style.input} ref={ref => this.fileUpload = ref} type="file" accept="image/x-png,image/jpeg" onChange={this.handleChange}/>
          </label>
        </figcaption>
      </figure>
    )
  }
}

export default ImageUploader
