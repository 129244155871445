import React, {Component} from 'react'

import style from './styles/Radio.module.scss'

class Radio extends Component {
  handleChange = ({target}) => {
    const {name, onChange} = this.props
    const {value} = target

    if (typeof onChange === 'function') {
      onChange(name, value)
    }
  }

  render() {
    const {title, inputs, value} = this.props
    const secureValue = value || ''

    return (
      <div className={style.normal}>
        <h4 className={style.title}>{title}</h4>
        <div className={style.buttons}>
          {inputs.map(i => (
            <label key={i.value} className={i.value === secureValue ? style.active : style.label}>
              {i.label}
              <input
                className={style.input}
                type="checkbox"
                value={i.value}
                onChange={this.handleChange}
              />
            </label>
          ))}
        </div>
      </div>
    )
  }
}

export default Radio
