import React from 'react'
import style from './index.module.scss'

const Container = ({children, className: mixin}) => (
  <div className={style.container + (mixin ? ` ${mixin}` : '')}>
    {children}
  </div>
)

export default Container
