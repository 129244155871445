import {connect} from 'react-redux'
import Cards from '../components/Cards'
import {fetchObjects, createObject} from '../actions/object'
import {selectObject} from '../actions/popups'
import {initSession, sendEvent} from '../actions/session'
import {fetchGeolocation} from '../actions/geolocation'
import {getAllObjects, getShowcaseObjects, getFetchingState} from '../selectors/objects'
import {isLoggedIn} from '../selectors/auth'
import {makeTail} from '../selectors/session'

const mapStateToProps = (state) => ({
  isFetching: getFetchingState(state),
  objects: isLoggedIn(state) ? getAllObjects(state) : getShowcaseObjects(state),
  isLoggedIn: isLoggedIn(state),
  tail: makeTail(state)
})

const mapDispatchToProps = (dispatch) => ({
  onMount() {
    dispatch(initSession())
    dispatch(fetchObjects())
    dispatch(fetchGeolocation())
  },
  onEdit(id) {
    dispatch(selectObject(id))
  },
  onCreate() {
    dispatch(createObject())
  },
  onClick(object) {
    dispatch(sendEvent({
      name: 'click_partner',
      data: {
        dbId: object.id,
        apiId: object.apiId,
        title: object.title
      }
    }))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cards)
