import React, {Component} from 'react'
import style from './styles/index.module.scss'

import Checkbox from './Checkbox'
import Group from './Group'
import Tabs from './Tabs'
import Tab from './Tab'
import Icon from '../../utility/Icon'

class Edit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      card: {
        ...props.card
      },
      mainTab: 'main',
      formTab: 'showcase'
    }
  }

  handleClose = (event) => {
    event.preventDefault()

    const {onClose} = this.props

    if (typeof onClose === 'function') {
      onClose()
    }
  }

  handleSave = (event) => {
    event.preventDefault()

    const {onSave} = this.props

    if (typeof onSave === 'function') {
      onSave(this.state.card)
    }
  }

  handleDelete = (event) => {
    event.preventDefault()

    const {onDelete} = this.props
    if (typeof onDelete === 'function') {
      onDelete()
    }
  }

  handleCancel = (event) => {
    event.preventDefault()

    const {onCancel} = this.props
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  handleChange = (name, value) => {
    if (typeof name === 'string') {
      this.setState(prev => ({
        card: {
          ...prev.card,
          [name]: value
        }
      }))
    } else {
      this.setState(prev => {
        const [domain, sub] = name
        const prevValue = prev.card[domain]
        return {
          card: {
            ...prev.card,
            [domain]: {
              ...prevValue,
              [sub]: value
            }
          }
        }
      })
    }
  }

  handleTab = (name, value) => {
    this.setState({[name]: value})
  }

  getInputs(forms) {
    return forms.filter(f => f.type === 'input')
  }

  getCheckboxes(forms) {
    return forms.filter(f => f.type === 'checkbox')
  }

  getRadio(forms) {
    return forms.filter(f => f.type === 'radio')
  }

  isOpened(tabsName, tab) {
    return this.state[tabsName] === tab
  }

  isObjectActive() {
    const {forms} = this.props
    const values = this.state.card.id ? this.state.card : null
    const isActiveForm = ['flags', 'isActive']
    const form = forms.filter(f => JSON.stringify(f.name) === JSON.stringify(isActiveForm))
    const {name} = form[0]
    const secureValue = values ? values[name[0]][name[1]] : null

    return <Checkbox
      key={name}
      name={name}
      className={style.isActive}
      value={secureValue}
      onChange={this.handleChange}
    />
  }

  render() {
    const {forms, mainTabs, formsTabs, mainGroups, formsGroups, formsOffers, isOpen} = this.props
    const {isPosting} = this.props.card
    const {title} = this.state.card
    const {mainTab, formTab} = this.state
    const values = this.state.card.id ? this.state.card : null

    return (
      <div className={isOpen ? style.open : style.normal}>
        <div className={isPosting ? style.posting : style.overlay}></div>
        <header className={style.header}>
          <h2 className={style.title}>
            {this.isObjectActive()}
            {title}
          </h2>
          <button className={style.close} onClick={this.handleClose}><Icon icon="close" /></button>
        </header>
        <section className={style.section}>
          <Tabs
            name="mainTab"
            current={mainTab}
            tabs={mainTabs}
            values={values}
            onTab={this.handleTab}
            onChange={this.handleChange}
          >
            {mainGroups.map(g => (
              <Tab key={g.id} isOpen={this.isOpened('mainTab', g.id)}>
                <Group
                  forms={forms}
                  group={g.forms}
                  card={values}
                  onChange={this.handleChange}
                />
              </Tab>
            ))}
          </Tabs>
          <Tabs
            name="formTab"
            current={formTab}
            tabs={formsTabs}
            values={values}
            onTab={this.handleTab}
            onChange={this.handleChange}
          >
            {formsGroups.map(g => (
              <Tab key={g.id} isOpen={this.isOpened('formTab', g.id)}>
                <Group
                  forms={forms}
                  group={g.forms}
                  card={values}
                  onChange={this.handleChange}
                />
              </Tab>
            ))}
          </Tabs>
          <Group
            title="Офферы"
            forms={forms}
            group={formsOffers}
            card={values}
            onChange={this.handleChange}
          />
        </section>
        <footer className={style.footer}>
          <ul className={style.buttons}>
            <li className={style.li}><button className={style.button} onClick={this.handleSave}>Сохранить</button></li>
            <li className={style.li}><button className={style.button} onClick={this.handleDelete}>Удалить</button></li>
          </ul>
          <button className={style.button} onClick={this.handleClose}>Отменить</button>
        </footer>
      </div>
    )
  }
}

export default Edit
