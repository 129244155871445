import {connect} from 'react-redux'

import {getEditingCard} from '../selectors/popups'

import {saveObject, updateObject} from '../actions/object'
import {closePopup} from '../actions/popups'

import Edit from '../components/Popups/Edit'

import {forms, mainTabs, formsTabs,
  mainGroups, formsGroups, formsOffers} from './data'

const mapStateToProps = (state) => {
  const card = getEditingCard(state)
  return {
    key: card.id,
    card,
    forms,
    mainTabs,
    formsTabs,
    mainGroups,
    formsGroups,
    formsOffers
  }
}

const mapDispatchToProps = (dispatch) => ({
  onSave(newCard) {
    if (newCard.isNew) {
      dispatch(saveObject(newCard))
      dispatch(closePopup())
    } else {
      dispatch(updateObject(newCard))
    }
  },
  onDelete() {
    console.log('delete')
  },
  onClose() {
    dispatch(closePopup())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
