import {connect} from 'react-redux'

import {isLoggedIn} from '../selectors/auth'

import Popups from '../components/Popups'

const mapStateToProps = (state) => ({
  popupsInfo: state.popup,
  isLoggedIn: isLoggedIn(state)
})

export default connect(
  mapStateToProps
)(Popups)
