import {AUTH_LOGIN} from '../actions/auth'

const initialState = {
  isPosting: false,
  token: null,
  hasErrors: false
}

function authReducer(state = initialState, action) {
  if (action.type === AUTH_LOGIN) {
    switch (action.payload.status) {
      case 1:
        return {...state, isPosting: true}
      case 2:
        return {
          ...state,
          isPosting: false,
          token: action.payload.token
        }
      default:
        return {
          ...state,
          isPosting: false,
          hasErrors: true
        }
    }
  }
  return state
}

export default authReducer
